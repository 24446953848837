<template>
    <div>

        <Header v-on:toggle="value => {this.$emit('toggle');} " :map="false" :closebutton="true">
            <div class="spacer-lg" />
            <h1 class="display-heading" v-html="title" />
            <div class="spacer-lg" />
        </Header>

        <div class="spacer" />

        <div class="container">
            <div class="row">
                <div class="col">
                    <div v-html="content.heading" />

                    <ol>
                        <li v-for="(para, index) in content.body" :key="index" v-html="para" />
                    </ol>

                    <div class="spacer-sm" />
                    <div v-html="content.footer" />

                    <div class="spacer-sm" />
                    <img :src="require('@/assets/images/email.png')" width="176" />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-right-sm">
                    <p v-html="copyright" />
                </div>
            </div>
        </div>

        <div class="spacer-lg" />

    </div>
</template>

<style lang="scss" scoped>
    .display-heading {
        text-align: center;
    }

    .temp {
        text-align: center;
        line-height: 6em;
        background-color: #3D4142;
    }
</style>

<script>
    import Header from '@/components/layout/Header.vue';
    //import Footer from '@/components/layout/Footer.vue';

    export default {
        name: "Terms",
        components: {
            Header,
            //Footer,
        },
        mounted() {
            window.scrollTo(0, 0)
        },
        data() {
            return {
                year: (new Date()).getFullYear(),
                title: 'Terms & Policies',
                heading: "ZOME Terms & Policies",
                content: {
                    heading: "By using ZOME, you agree to be bound by these terms of service (\"Terms of Service\"). The Service is owned and provided to you by ZOME. These Terms of Use therefore constitute an agreement between you and the company.",
                    body: [
                        "You must be at least 13 years old to use the Service.",
                        "You may not post violent, nude, partially nude, pornographic, discriminatory, defamatory, threatening, hateful, unlawful, racially or ethnically offensive materials or other inapposite content via the Service.",
                        "You are responsible for keeping your login information safe and secure.",
                        "You agree that you will not use or collect the login credentials of other ZOME users without their permission.",
                        "You must not use the Service for any illegal or fraudulent purpose. You will comply with all local, state, federal and international laws and regulations, including copyright laws.",
                        "You are responsible for any data, photographs, audio, video, text files, user information, profiles, links and all other materials that you submit, send or post on the Service.",
                        "You will not use any bot, spider, scraper or other automated devices or interface to access the Services or extract and collect other user’s information.",
                        "You must not send, store, or transmit viruses or other harmful computer code of a destructive or disruptive nature through or onto our Services.",
                        "You must not create or send unwanted spam content or other forms of harassing communications to any ZOME users.",
                        "You must not stalk, harass, bully, abuse, impersonate, defame, threaten or intimidate people, entities or other ZOME users.",
                    ],
                    footer: "Violation of these Terms of Service may result in deletion of your content or your ZOME account. You are responsible for the Content create, send, post and store on the Service.If you violate these Terms of Service, we can stop providing all or part of the Service to you.",
                }
            };
        },
        computed: {
            rhizomenet() {
                return "Rhizomenet Pty. Ltd. " + this.year;
            },
            copyright() {
                return "Copyright &copy; " + this.year;
            },
        },
        head() {
            return {
                meta: [
                    { hid: 'description', name: 'description', content: 'Terms & Policies' },
                ]
            };
        },


    }
</script>