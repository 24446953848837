<template>
  <div>
    <Header :map="true">
      <div class="row">
        <div class="spacer-lg" />

        <div class="col-sm-6">
          <h1 class="display-heading">Augmented<br />Reality<br />Messenger</h1>

          <div class="spacer" />

          <div class="downloads">
            <a href="https://itunes.apple.com/us/app/zome/id1382123356?ls=1&mt=8" target="_blank">
              <img :src="require('@/assets/images/download/apple.png')" width="120" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.zomecorp.zome" target="_blank">
              <img :src="require('@/assets/images/download/google.png')" width="120" />
            </a>
          </div>

          <div class="spacer tablet-up" />
        </div>

        <div class="col header-phones">
          <div class="row">
            <div class="col">
              <div class="phone">
                <img :src="require('@/assets/images/screenshots/screenshot-1.jpg')" />
              </div>
            </div>
            <div class="col">
              <div class="phone">
                <img :src="require('@/assets/images/screenshots/screenshot-2.jpg')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Header>

    <div class="container">
      <div class="row">
        <div class="col-sm-3">
          <div class="value-prop">
            <div class="spacer mobile" />
            <img :src="require('@/assets/images/icons/pulse.png')" class="value-prop__icon" width="34" />
            <p class="value-prop__body">
              Plant Your Message<br />Anywhere
            </p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="value-prop">
            <img :src="require('@/assets/images/icons/bell.png')" class="value-prop__icon" width="26" />
            <p class="value-prop__body">
              Be everywhere;<br />Message in Space & Time
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="spacer-md" />

    <Features />

    <div class="spacer-md" />

    <div class="container">
      <ParticleBackground>
        <HorizontalAccent />
        <div class="container">
          <div class="row">
            <div class="col-sm-12">

              <h2 class="color-white">{{ companyprofile.heading }}</h2>

              <p v-for="(para, index) in companyprofile.body" :key="index" class="mono-type" v-html="para" />
            </div>
          </div>
          <div class="spacer-md" />
        </div>
      </ParticleBackground>
    </div>


    <Footer v-on:toggle="value => {this.$emit('toggle');} " />

    <div class="spacer-lg" />
  </div>
</template>

<style lang="scss" scoped>
  .header-phones {
    margin: 2rem 0;

    @media (min-width: 576px) {
      margin-bottom: -2rem;
    }
  }
</style>

<script>
  import Header from "@/components/layout/Header.vue";
  import Features from "@/components/sections/Features.vue";
  import HorizontalAccent from "@/components/elements/HorizontalAccent.vue";
  import ParticleBackground from "@/components/sections/ParticleBackground.vue";
  import Footer from "@/components/layout/Footer.vue";

  export default {
    name: "Home",
    components: {
      Header,
      Features,
      HorizontalAccent,
      ParticleBackground,
      Footer,
    },
    data() {
      return {
        year: new Date().getFullYear(),
        currentPage: 0,
        heading: "ZOME Features",
        companyprofile: {
          heading: "Company",
          body: [
            "We believe that building a great AR communication platform will make people’s lives easier and more efficient.",
            "Space and time based information has more value and relevance to people, based on where they are in the moment.",
            "ZOME will help people to create, explore, express themselves, share knowledge and memories with each other.",
          ],
        },
      };
    },
    computed: {
      copyright() {
        return "Copyright &copy; " + this.year;
      },
    },
  };
</script>