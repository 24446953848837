<template>
  <div class="container features-container" style="display:block;">
    <div class=" row">
      <div class="col text-center">
        <h2 class="color-white">
          {{ heading }}
        </h2>
      </div>
    </div>

    <div class="spacer" />

    <div class="row">
      <div class="col-sm-5">
        <div class="feature-preview desktop">
          <transition-group name="slide" tag="div">
            <div v-for="(feature, index) in features" v-show="index === currentPage" :key="feature.index"
              class="feature-preview__item">

              <div class="feature-preview__item__secondary desktop">
                <img :src="feature.secondary" />
              </div>

              <div class="feature-preview__item__primary phone desktop">
                <img :src="feature.primary" />
              </div>

            </div>
          </transition-group>
        </div>

      </div>

      <div class="col-sm-5 offset-sm-1">
        <div class="spacer mobile" />
        <FeatureCarousel @page-change="setPage" />
      </div>
    </div>

  </div>
</template>

<script>
  import throttle from "lodash/throttle";
  import FeatureCarousel from "./FeatureCarousel";

  export default {
    components: {
      FeatureCarousel,
    },
    computed: {
      showCarousel() {
        console.log('showCarousel: ' + this.width + ' : ' + (this.isMounted && this.width < 576));
        return this.isMounted && this.width < 576;
      },
    },
    data() {
      return {
        currentPage: 0,
        heading: "ZOME Features",
        features: [
          {
            index: 0,
            primary: require("@/assets/images/screenshots/screenshot-3.jpg"),
            secondary: require("@/assets/images/screenshots/screenshot-3.jpg"),
          },
          {
            index: 1,
            primary: require("@/assets/images/screenshots/screenshot-4.jpg"),
            secondary: require("@/assets/images/screenshots/screenshot-4.jpg"),
          },
          {
            index: 2,
            primary: require("@/assets/images/screenshots/screenshot-5.jpg"),
            secondary: require("@/assets/images/screenshots/screenshot-5.jpg"),
          },
          {
            index: 3,
            primary: require("@/assets/images/screenshots/screenshot-6.jpg"),
            secondary: require("@/assets/images/screenshots/screenshot-6.jpg"),
          },
          {
            index: 4,
            primary: require("@/assets/images/screenshots/screenshot-7.jpg"),
            secondary: require("@/assets/images/screenshots/screenshot-7.jpg"),
          },
        ],
      };
    },
    mounted() {
      this.isMounted = true;
      this.resize();
      window.addEventListener("resize", throttle(this.resize.bind(this), 500));
    },
    methods: {
      setPage(val) {
        console.log('currentpage: ' + val);
        this.currentPage = val;
      },
      windowWidth() {
        return (
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        );
      },

      resize() {
        this.width = this.windowWidth();
        this.activeIndex = 0; // Reset to trigger the currentPage watcher
      },
    },
  };
</script>