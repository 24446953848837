<template>
  <div :class="{ 'nav--logo': logo }" class="nav">
    <ul class="nav__left">
      <li>
        <Logo />
      </li>
    </ul>
  </div>
</template>

<script>
  import Logo from "../elements/Logo";

  export default {
    components: {
      Logo,
    },

    props: {
      logo: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>