<template>
	<div>
		<div v-if="!showTerms">
			<Home v-on:toggle="value => { toggleTerms() }" />
		</div>
		<div v-if="showTerms">
			<Terms v-on:toggle="value => { toggleTerms() }" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.header-phones {
		margin: 2rem 0;

		@media (min-width: 576px) {
			margin-bottom: -2rem;
		}
	}
</style>

<script>
	import Home from "@/components/pages/Home.vue";
	import Terms from "@/components/pages/Terms.vue";

	export default {
		name: "Index",
		components: {
			Home,
			Terms
		},
		methods: {
			toggleTerms() {
				this.showTerms = !this.showTerms
			}
		},
		data() {
			return {
				showTerms: false,
			};
		}
	};
</script>