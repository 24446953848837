<template>
  <div class="header">
    <div v-if="map">
      <WorldMap class="world-map" />
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div v-on:click="$emit('toggle');" style="cursor: pointer">
            <Nav :logo="true" />
            <slot />
          </div>
        </div>

        <div v-if="closebutton">
          <div class="closebutton" v-on:click="$emit('toggle');" style="cursor: pointer">
            <img :src="require('@/assets/images/icons/cross.png')" />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header {
    position: relative;
  }

  .closebutton {
    height: 40px;
    width: 40px;
    padding: 35px 15px 0 0;
  }

  .world-map {
    position: absolute;

    top: 0;
    right: 0;
    bottom: -20%;

    width: 100%;
    max-width: 1140px;

    left: 50%;
    transform: translateX(-50%);

    overflow: hidden;
  }
</style>

<script>
  import Nav from "./Nav";
  import WorldMap from "../elements/WorldMap";

  export default {
    components: {
      Nav,
      WorldMap,
    },
    methods: {
      toggleTerms() {
        console.log('click');
        //$emit('toggle');
      }
    },
    props: {
      map: {
        type: Boolean,
        default: false,
      },
      closebutton: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>