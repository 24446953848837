<template>
  <div class="world-map">
    <WorldMapSVG />
  </div>
</template>

<style lang="scss">
  .world-map svg {
    float: right;
  }

  circle {
    z-index: 0;
    opacity: 0.25;
  }

  circle.active,
  circle.active-highlight {
    fill: #106ce0;
    stroke: #106ce0;
  }

  circle.active-highlight {
    fill: transparent;

    opacity: 1;
    stroke-width: 125;
    z-index: 100;

    will-change: stroke-opacity, stroke-width;
    animation: pulse infinite 3s;
  }

  @keyframes pulse {
    0% {
      stroke-opacity: 1;
      stroke-width: 15;
    }

    100% {
      stroke-opacity: 0;
      stroke-width: 125;
    }
  }
</style>

<script>
  import WorldMapSVG from "@/assets/images/svg/world-map.svg";

  export default {
    name: "WorldMap",
    components: {
      WorldMapSVG,
    },

    data() {
      return {
        circles: [],
        circleCount: 0,
        activeCircles: 6,
      };
    },

    mounted() {
      this.circleCount = this.$el.getElementsByTagName("circle").length;

      if (!this.circleCount) {
        return;
      }

      for (let i = 0; i < this.activeCircles; i++) {
        this.activateCircle(this.getRandomCircle());
      }
    },

    methods: {
      getRandomCircle() {
        let number = Math.floor(Math.random() * this.circleCount);

        return this.$el.getElementsByTagName("circle")[number];
      },

      activateCircle(circle) {
        setTimeout(() => {
          let circleHighlight = circle.cloneNode();

          document.getElementsByTagName("svg")[0].appendChild(circleHighlight);

          circle.classList.add("active");
          circleHighlight.classList.add("active-highlight");
        }, Math.random() * 3000); // Stagger over 3 seconds
      },
    },
  };
</script>