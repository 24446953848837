<template>
  <carousel v-if="showCarousel" ref="carousel" :per-page="1" :loop="true" :autoplay="true" :autoplay-timeout="6000"
    :autoplay-hover-pause="true" :pagination-size="5" pagination-active-color="#FFFFFF" pagination-color="#3D4142">

    <slide v-for="(feature, tIndex) in items" :key="feature.title">

      <div v-for="(feature, index) in featureimages" v-show="index === tIndex" :key="index"
        class="feature-preview__item">

        <div class="feature-preview__item__primary phone">
          <img :src="feature.primary">
        </div>

      </div>

      <div class="value-prop" style="padding-top: 40px">
        <h3 class="value-prop__title">{{ feature.title }}</h3>
        <p class="value-prop__body">{{ feature.body }}</p>
      </div>

    </slide>

  </carousel>
  <div v-else>
    <div v-for="(feature, index) in items" :key="index" :class="{'value-prop--active': index === activeIndex}"
      class="value-prop value-prop--selectable" @click="setActiveIndex(index)">
      <h3 class="value-prop__title">{{ feature.title }}</h3>
      <p class="value-prop__body">{{ feature.body }}</p>
    </div>
  </div>
</template>

<style lang="scss">
  .VueCarousel-dot {
    margin-top: 0 !important;
  }
</style>

<script>
  import throttle from "lodash/throttle";
  import { Carousel, Slide } from "vue-carousel";

  export default {
    components: {
      Carousel,
      Slide,
    },

    data() {
      return {
        isMounted: false,
        width: 0,
        activeIndex: 0,
        featureimages: [
          {
            primary: require('@/assets/images/screenshots/screenshot-3.jpg')
          },
          {
            primary: require('@/assets/images/screenshots/screenshot-4.jpg')
          },
          {
            primary: require('@/assets/images/screenshots/screenshot-5.jpg')
          },
          {
            primary: require('@/assets/images/screenshots/screenshot-6.jpg')
          },
          {
            primary: require('@/assets/images/screenshots/screenshot-7.jpg')
          }
        ],
        items: [
          {
            index: 0,
            title: "AR Messages",
            body: "Share and create your own content in AR.",
          },
          {
            index: 1,
            title: "Time Based Messages",
            body: "Time capsules that can only be opened at set times in the future.",
          },
          {
            index: 2,
            title: "Location Based Messages",
            body: "Location specific messages that encourage you to explore.",
          },
          {
            index: 3,
            title: "Interface Design",
            body: "Contemporary and elegant user interface design.",
          },
          {
            index: 4,
            title: "Augmented Reality",
            body: "Unique AR features designed for all Android and Apple smart phone users.",
          },
        ],
      };
    },

    computed: {
      showCarousel() {
        return this.isMounted && this.width < 576;
      },

      currentPage() {
        const index = this.activeIndex;

        if (!this.$refs.carousel || !this.showCarousel) {
          return index;
        }

        console.log(this.$refs.carousel.currentPage);

        return this.$refs.carousel.currentPage;
      },
    },

    watch: {
      currentPage() {
        this.$emit("page-change", this.currentPage);
      },
    },

    mounted() {
      this.isMounted = true;

      this.resize();
      window.addEventListener("resize", throttle(this.resize.bind(this), 500));
    },

    methods: {
      windowWidth() {
        return (
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
        );
      },

      resize() {
        this.width = this.windowWidth();
        this.activeIndex = 0; // Reset to trigger the currentPage watcher
      },

      setActiveIndex(index) {
        //print('setActiveIndex: ' + index);
        this.activeIndex = index;
        //this.$emit("page-change", index);
      },
    },
  };
</script>