<template>
  <div class="footer">

    <div class="container">
      <div class="row">
        <div class="col-sm-8">
          <div class="container">
            <h5>
              For ZOME Public Account access and commercial requests, please
              contact us directly.
            </h5>
          </div>
        </div>
        <!--div class="col-sm-4" v-on:click="$emit('toggle');"-->
        <div class="col-sm-4">
          <div class="container">
            <a href="http://www.zome.app/privacy" target="_blank" rel="noopener noreferrer" >
              <h5 class="bold" style="float: right; color: white; cursor: pointer">
                Terms &amp; Policies
              </h5>
            </a>
          </div>
        </div>

        <div class="container">
          <HorizontalAccent />
        </div>

        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-right-sm">
              <div class="container">
                <p v-html="copyright" />
              </div>
            </div>
          </div>
        </div>

        <div class="spacer-xl" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .footer {
    font-size: 14px;
  }

  .nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  p {
    font-size: 12px;
    line-height: 16px;
    margin: 14px 0;
  }
</style>

<script>
  import HorizontalAccent from "@/components/elements/HorizontalAccent.vue";

  export default {
    name: "Footer",
    components: {
      HorizontalAccent
    },
    data() {
      return {
        year: (new Date()).getFullYear()
      };
    },
    computed: {
      copyright() {
        return "Copyright &copy; " + this.year;
      },
    },
  }
</script>