import '@/scss/app.scss'
import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VueParticles from 'vue-particles'

/*Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  refreshOnceOnNavigation: true
})*/

Vue.use(VueMeta)
Vue.use(VueParticles)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
