<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
  import Index from "./components/Index.vue";

  export default {
    name: "App",
    components: {
      Index,
    },
    data() {
      return {
        title: 'ZOME',
      };
    },
    metaInfo() {
      return {
        title: 'ZOME',
        titleTemplate: null,
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
          { name: 'apple-itunes-app', content: 'app-id=1382123356' },
          { name: 'description', content: 'ZOME is an Augmented Reality Messenger and Network, where you can plant your own digital content anywhere in the world instead of just being online. Plant Your Message Anywhere.' },
        ],
        link: [
          { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
          { rel: 'manifest', href: './manifest.json' }
        ]
      }
    }
  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>